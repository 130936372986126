import { MetaBroker, FormattedPositions } from 'state/mock'
import { DashTab, selectIsBlocked, showBlock } from 'state/slices/ui/dash'
import {
  buildIncentiveData,
  FormTab,
  openDetailsWithBroker,
  openFormWithData
} from 'state/slices/ui/form'
import {
  calcBorrowData,
  calcSupplyData,
  selectMaxBorrowForAsset
} from 'state/slices/user/portfolio'
import InfoPoint, { InfoPointProps } from '../common/InfoPoint'
import {
  formatCurrency,
  formatPercentage,
  prettyTokenBal,
  prettyTokenBalWithK
} from 'toolbox/format'
import ActionCircle from '../common/ActionCircle'
import MetaDisplay from './MetaDisplay'
import { types } from '@concordia/super-sdk'
import { calcUtil } from 'toolbox/calc'
import { getWalletBalanceByName } from 'state/slices/user/walletBalances'
import { useState } from 'react'
import { useAppSelector } from 'state/hooks'
import ParamList, { ParamListProps } from 'components/txForm/ParamList'
import { RewardRate } from 'components/rewards/RewardRateDisplay'
import { selectIncentivesToggleOn } from 'state/slices/app/toggles'
import { getPriceFromName, selectPricesFromBrokers } from 'state/slices/app/brokers'
import { Tooltip } from 'components/common/Tooltip'

export const COLLATERAL = 'Supply'
export const BORROW = 'Borrow'
export interface CollateralRowProps {
  b: MetaBroker
  tab: DashTab
  isLoadedUser: boolean
  positions: FormattedPositions
  balances: types.SWalletBalance[]
  showBalRow: boolean
  isLoadedApp: boolean
  formTabs: FormTab[]
  userCollat: number
  isLoadedBrokers: boolean
}

function CollateralRow({
  b,
  tab,
  isLoadedUser,
  positions,
  balances,
  showBalRow,
  isLoadedApp,
  formTabs,
  userCollat,
  isLoadedBrokers
}: CollateralRowProps) {
  const hasSupplied = userCollat > 0
  const hasPower = 0 < 1
  const canBorrow = hasSupplied && hasPower
  const token = b.tokenMeta
  // const rowRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)
  const maxBorrowForAsset = useAppSelector((s) => selectMaxBorrowForAsset(s, b.loanNote.name))
  const isBlocked = useAppSelector(selectIsBlocked)
  const maxSupplyBroker = Number(b?.maxDepositScaled)
  const incentivesToggleOn = useAppSelector(selectIncentivesToggleOn)
  const useIncentives =
    (incentivesToggleOn && b?.underlyingAsset.name === 'usdtlz') ||
    (incentivesToggleOn && b?.underlyingAsset.name === 'usdc')
  const prices = useAppSelector(selectPricesFromBrokers)
  const aptPrice = getPriceFromName('aptos', prices)

  const openDetails = () => (isBlocked ? showBlock() : openDetailsWithBroker(b))

  const onHover = () => {
    setIsHovered(true)
  }

  const onLeave = () => {
    setIsHovered(false)
  }

  if (!token.logo) return null
  const chain = b.chainMeta
  const balance = getWalletBalanceByName(balances, token.name)
  const utilization = calcUtil(b)
  const assetPrice = b.underlyingAsset.price
  const borrowData = calcBorrowData(positions, b)
  const supplyData = calcSupplyData(positions, b, utilization)
  const brokerSupply = b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying
  const brokerSupplyValue = brokerSupply * assetPrice
  const displayBal = !isLoadedUser && balance === 0 ? '--' : `${prettyTokenBal(balance)}`

  const incentiveData = buildIncentiveData(b, aptPrice, useIncentives)
  const { supplyRewardRate, borrowRewardRate } = incentiveData

  const openAdd = () => (isBlocked ? showBlock() : openFormWithData(b, incentiveData, formTabs[0]))
  const open = () => (isBlocked ? showBlock() : openFormWithData(b, incentiveData, formTabs[1]))
  if (!b) {
    return null
  }

  const meta = {
    firstIcon: chain.logoURL,
    secondIcon: token.logo,
    value: token.ticker,
    sub: displayBal,
    secondarySub: 'in wallet',
    isLoadedAny: true,
    divider: true,
    isHover: isHovered,
    hideSecondaryOnBreakpoint: true,
    tooltip: token.ticker,
    assetHover: token.displayName,
    fitWidth: true
  }

  const borrowValue: InfoPointProps = {
    value: isLoadedUser ? prettyTokenBal(borrowData.exchangeRateBalance) : '--',
    sub: isLoadedUser ? formatCurrency(borrowData.exchangeRateValue) : null,
    disabled: !isLoadedUser
  }

  const supplyValue: InfoPointProps = {
    value: isLoadedUser ? prettyTokenBal(supplyData.exchangeRateBalance) : '--',
    sub: isLoadedUser ? formatCurrency(supplyData.exchangeRateValue) : null,
    disabled: !isLoadedUser
  }

  const hoverParams: ParamListProps = {
    params: [
      { label: 'Total supplied', value: prettyTokenBal(brokerSupply) },
      { label: 'Pool max limit', value: prettyTokenBal(maxSupplyBroker) }
    ]
  }

  const totalSupplyValue: InfoPointProps = {
    value: isLoadedApp ? prettyTokenBalWithK(brokerSupply) : '--',
    sub: isLoadedBrokers ? formatCurrency(brokerSupplyValue) : '--',
    disabled: !isLoadedApp,
    slashValue: isLoadedBrokers ? prettyTokenBalWithK(maxSupplyBroker) : '--',
    hoverValue: <ParamList {...hoverParams} />
  }

  const maxBorrowValue: InfoPointProps = {
    value: isLoadedUser ? prettyTokenBal(maxBorrowForAsset.maxBorrowUnderlying) : '--',
    sub: isLoadedUser ? formatCurrency(maxBorrowForAsset.maxBorrowValueUSD) : null,
    disabled: !isLoadedUser
  }

  const totalAvailableValue: InfoPointProps = {
    value: isLoadedApp ? prettyTokenBal(b.scaledAvailableLiquidityUnderlying) : '--',
    sub: isLoadedApp ? formatCurrency(b.scaledAvailableLiquidityUnderlying * assetPrice) : null,
    disabled: !isLoadedApp
  }

  const disAddDeposit = !isLoadedUser
  const disAddBorrow = !isLoadedUser || !canBorrow
  const disRemoveDeposit = !isLoadedUser || !supplyData.hasPosition
  const disRemoveBorrow = !isLoadedUser || !borrowData.hasPosition

  const disabledAddSwitch = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return disAddDeposit
      case BORROW:
        return disAddBorrow
    }
  }

  const disabledMinusSwitch = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return disRemoveDeposit
      case BORROW:
        return disRemoveBorrow
    }
  }

  const isSupply = tab === COLLATERAL

  const buttons = (
    <div className="broker-buttons">
      <ActionCircle cb={openAdd} disabled={disabledAddSwitch(tab)} isAdd isSupply={isSupply} />
      <ActionCircle cb={open} disabled={disabledMinusSwitch(tab)} isSupply={isSupply} />
    </div>
  )

  const doDisplay = () => {
    switch (tab) {
      case COLLATERAL:
        return (
          <div className="collateral-row" onMouseEnter={onHover} onMouseLeave={onLeave}>
            <div className="columns inner">
              <div className="column is-4">
                <MetaDisplay {...meta} openForm={openDetails} />
              </div>
              <div className="column is-2">
                <InfoPoint {...supplyValue} />
              </div>
              <div className="column is-2">
                <p className="point med">{formatPercentage(utilization)}</p>
              </div>
              <div className="column is-2">
                <InfoPoint {...totalSupplyValue} />
              </div>
              <div className="column is-2">
                <p className="point med">
                  {' '}
                  {useIncentives
                    ? formatPercentage(supplyData.supplyAPR + supplyRewardRate)
                    : formatPercentage(supplyData.supplyAPR)}
                </p>
                {!!incentivesToggleOn && useIncentives && (
                  <Tooltip
                    label=""
                    value={`Supply ${
                      b?.tokenMeta.ticker
                    } on Superposition and earn ${formatPercentage(
                      supplyRewardRate
                    )} APR in APT rewards, in addition to the ${formatPercentage(
                      supplyData.supplyAPR
                    )} APR in lending interest`}>
                    <RewardRate
                      rewardRate={supplyRewardRate}
                      timeFrame="2h"
                      baseRate={supplyData.supplyAPR}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            {buttons}
          </div>
        )
      case BORROW:
        return (
          <div className="collateral-row" onMouseEnter={onHover} onMouseLeave={onLeave}>
            <div className="columns inner">
              <div className="column is-4">
                <MetaDisplay {...meta} openForm={openDetails} />
              </div>
              <div className="column is-2">
                <InfoPoint {...borrowValue} />
              </div>
              <div className="column is-2">
                <InfoPoint {...maxBorrowValue} />
              </div>
              <div className="column is-2">
                <InfoPoint {...totalAvailableValue} />
              </div>
              <div className="column is-2">
                <p className="point med">
                  {useIncentives
                    ? formatPercentage(borrowData.borrowAPR - borrowRewardRate)
                    : formatPercentage(borrowData.borrowAPR)}
                </p>
                {!!incentivesToggleOn && useIncentives && (
                  <Tooltip
                    label=""
                    value={`Borrow ${
                      b?.tokenMeta.ticker
                    } on Superposition and earn ${formatPercentage(
                      borrowRewardRate
                    )} APR in APT rewards, which can offset the ${formatPercentage(
                      borrowData.borrowAPR
                    )} APR in borrowing interest`}>
                    <RewardRate
                      rewardRate={borrowRewardRate}
                      timeFrame="2h"
                      isBorrow
                      baseRate={borrowData.borrowAPR}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            {buttons}
          </div>
        )
    }
  }

  const row = () => {
    switch (tab) {
      case COLLATERAL: {
        if (showBalRow && supplyData.hasPosition) {
          return doDisplay()
        } else if (!showBalRow && !supplyData.hasPosition) {
          return doDisplay()
        }
        break
      }
      case BORROW: {
        if (showBalRow && borrowData.hasPosition) {
          return doDisplay()
        } else if (!showBalRow && !borrowData.hasPosition) {
          return doDisplay()
        }
        break
      }
    }
  }

  return <>{row()}</>
}

export default CollateralRow
