import { createAsyncThunk } from '@reduxjs/toolkit'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { MetaBroker } from 'state/mock'
import { SDK_CONTEXT } from 'state/context'
import { eMessage } from 'toolbox/format'
import { SuperpositionAptosSDK, MultiplySDK } from '@concordia/super-aptos-sdk'
import { SignAndSubmitTransactionCallback } from './doMoneyGun'
import {
  AptosConfig,
  Aptos,
  Hex,
  Network,
  InputViewFunctionData,
  MoveFunctionId,
  MoveStructId
} from '@aptos-labs/ts-sdk'
import { InputTransactionData } from '@aptos-labs/wallet-adapter-core'
import { closeMultiplyForm, closeMultiReview } from 'state/slices/ui/form'
import { CLOSE_MULTIPLY_ACTION, setTxSuccessState } from 'state/slices/ui/transaction'
import { WaitArgs } from './doTx'
import { fetchAppData } from 'state/fetch'

export interface ClosePositionTxPayload {
  network: string
  repayBroker: MetaBroker
  redeemBroker: MetaBroker
  user: string
  signAndSub: SignAndSubmitTransactionCallback
  minLiabilityCoinAmountOut: number
}

export const MULTIPLY_ADDRESS_TESTNET =
  '0xc9324f0a32efe3c9cb8ee32023b9fd6eeec760e004a2d4844a0ac0e036d7112c'
export const MULTIPLY_ADDRESS_MAINNET =
  '0x4dc16e1fe9872adce33d0bbf7f64f4db638a60fd6170f680ed0c3159891ae9f0'

const APP_NETWORK = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'
export const MULTIPLY_ADDRESS =
  APP_NETWORK === 'mainnet' ? MULTIPLY_ADDRESS_MAINNET : MULTIPLY_ADDRESS_TESTNET

async function getVaultAddress(
  aptos: Aptos,
  vaultOwner: string,
  lendCoinType: MoveStructId,
  borrowCoinType: MoveStructId
): Promise<string> {
  const payload: InputViewFunctionData = {
    function: `${MULTIPLY_ADDRESS}::multiply_public::get_vault_address`,
    functionArguments: [vaultOwner],
    typeArguments: [lendCoinType, borrowCoinType]
  }
  const [vaultAddress] = (await aptos.view({
    payload
  })) as [string]
  return vaultAddress
}

export const doClosePosition = createAsyncThunk(
  'closePosition',
  async (payload: ClosePositionTxPayload): Promise<any> => {
    toastLoad('Building transaction...')

    const envNetwork = process.env.REACT_APP_APTOS_NETWORK_SHORT
    const useNetwork = envNetwork === 'mainnet' ? Network.MAINNET : Network.TESTNET
    const aptosConfig = new AptosConfig({
      network: useNetwork
    })
    const aptos = new Aptos(aptosConfig)
    const superSdk = SDK_CONTEXT.superSdk
    const rootAddr = process.env.REACT_APP_APTOS_ROOT_ADDRESS
    const superAptosSDK = new SuperpositionAptosSDK(rootAddr)
    const multiplySDK = new MultiplySDK(MULTIPLY_ADDRESS, rootAddr)

    if (!rootAddr || !superSdk || !envNetwork || !aptos || !superAptosSDK) {
      console.log('rootAddress: ', rootAddr)
      console.log('superSdk: ', superSdk)
      console.log('Env network: ', envNetwork)
      console.log('aptosSDK: ', aptos)
      console.log('SuperpositionAptosSDK: ', superAptosSDK)
      dismissNotifyThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const { network, repayBroker, redeemBroker, user, minLiabilityCoinAmountOut } = payload

    if (minLiabilityCoinAmountOut == null) {
      dismissNotifyThrow(
        'Missing minimum amount',
        'The transaction request is missing a minimum amount expected to be received, please try again'
      )
    }

    if (minLiabilityCoinAmountOut <= 0) {
      dismissNotifyThrow(
        'Invalid minimum amount',
        `The minimum amount expected to be received must be greater than 0 but it was set to ${minLiabilityCoinAmountOut}`
      )
    }

    if (!network || !repayBroker || !redeemBroker || !user) {
      dismissNotifyThrow(
        'Missing arguments',
        'The transaction request is missing required arguments, please try again'
      )
    }

    let vaultAddress = ''

    const liabilityCoinType = repayBroker.underlyingAsset.networkAddress
    const collateralCoinType = redeemBroker.underlyingAsset.networkAddress

    try {
      toastLoad('Getting vault...')
      vaultAddress = await getVaultAddress(
        aptos,
        user,
        collateralCoinType as MoveStructId,
        liabilityCoinType as MoveStructId
      )
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Failed to get user vault', eMessage(e))
    }

    let depositNoteBalance
    let loanNoteBalance
    let currentPortfolioState
    try {
      const vaultPortfolio = await superSdk.fetcher.getUserVault(
        user,
        redeemBroker.underlyingAsset.name,
        repayBroker.underlyingAsset.name
      )
      depositNoteBalance = vaultPortfolio.vault_balance.collaterals[0].amount
      loanNoteBalance = vaultPortfolio.vault_balance.liabilities[0].amount
      currentPortfolioState = vaultPortfolio.vault_balance
      // The vault portfolio state is returning the network
      // address of each coin instead of the instrument ID
      currentPortfolioState.collaterals[0].instrumentId = redeemBroker.depositNote.name
      currentPortfolioState.liabilities[0].instrumentId = repayBroker.loanNote.name
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Failed to fetch vault portfolio', eMessage(e))
    }

    const repayAmount = Math.floor(loanNoteBalance).toString()
    const redeemAmount = Math.floor(depositNoteBalance).toString()

    toastLoad('Evaluating risk of closing multiply vault...')
    let packet
    try {
      packet = await superSdk.closeMultiplyVault({
        network,
        repayBrokerName: repayBroker.underlyingAsset.name,
        repayAmount,
        redeemBrokerName: redeemBroker.underlyingAsset.name,
        redeemAmount,
        user: vaultAddress,
        currentPortfolioState
      })
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow(`Close Multiply Vault Risk Evaulation Failed`, eMessage(e))
    }

    toastLoad('Signing transaction...')
    let hash
    try {
      const packetHexString = Hex.fromHexString(packet.packet)
      const packetByteArray = packetHexString.toUint8Array()
      // TODO: Calculate minLiabilityCoinAmountOut by
      // estimating the expected amount of liability coins
      // after trading the collateral back to the
      // principal coin denomination and then applying slippage
      const ix = multiplySDK.closeMultiplyVaultIx(
        packetByteArray,
        collateralCoinType,
        liabilityCoinType,
        minLiabilityCoinAmountOut
      )

      const v2Ix: InputTransactionData = {
        sender: payload.user,
        data: {
          function: ix.function as MoveFunctionId,
          typeArguments: ix.type_arguments,
          functionArguments: ix.arguments
        }
      }

      hash = await payload.signAndSub(v2Ix)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Transaction Not Submitted', eMessage(e))
    }

    toastLoad('Waiting for transaction confirmation...')
    try {
      const waitArgs: WaitArgs = {
        transactionHash: hash?.hash,
        options: {
          checkSuccess: true
        }
      }

      const result = await aptos.waitForTransaction(waitArgs)
      console.log(`Close vault result`, result)
      toastSuccess('Closed multiply vault successfully')
      closeMultiplyForm()
      // avoid displaying the review next time the multiply form is opened
      closeMultiReview()
      setTxSuccessState({
        amount: null,
        ticker: repayBroker.underlyingAsset.name,
        txHash: hash?.hash,
        action: CLOSE_MULTIPLY_ACTION,
        decimals: repayBroker.underlyingAsset.decimals
      })
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Closing multiply vault failed', eMessage(e))
    }
    fetchAppData()
  }
)
