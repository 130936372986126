import { ReactNode } from 'react'
import MetaWalletContext from 'components/wallet/MetaWalletContext'
import { AptosWalletAdapterProvider } from '@aptos-labs/wallet-adapter-react'
import { PontemWallet } from '@pontem/wallet-adapter-plugin'
import { MSafeWalletAdapter } from '@msafe/aptos-wallet-adapter'

export interface SuperWalletProviderProps {
  children: ReactNode
}

const envNet = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'
const isMainnet = envNet === 'mainnet'

const msafeURL = isMainnet ? 'https://aptos.m-safe.io' : 'https://aptos-testnet.m-safe.io'

export const wallets = [new PontemWallet(), new MSafeWalletAdapter(msafeURL)]

function SuperWalletProvider({ children }: SuperWalletProviderProps) {
  return (
    <AptosWalletAdapterProvider
      autoConnect
      plugins={wallets}
      // Exclude wallets that are not supported yet like
      // "Connect with Google" of "Dev T"
      optInWallets={['Petra', 'Nightly']}
      onError={(e: Error) => {
        console.log('Wallet Error:', e)
      }}>
      <MetaWalletContext>{children}</MetaWalletContext>
    </AptosWalletAdapterProvider>
  )
}

export default SuperWalletProvider
