import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { AptosConfig, Aptos, Network, MoveResource } from '@aptos-labs/ts-sdk'
import { postTransactionRefresh } from 'state/fetch'
import { InputTransactionData, useWallet } from '@aptos-labs/wallet-adapter-react'
import { isEmptyOrNil } from 'toolbox/account'
import { AccountArgs, WaitArgs } from './doTx'
import { eMessage } from 'toolbox/format'

export const COIN_A = 'CoinA'
export const COIN_B = 'CoinB'
export const COIN_C = 'CoinC'
export const STH_APT = 'StakedThalaAPT'
export const AM_APT = 'AmnisAPT'
export const STAKED_APT = 'StakedAPT'
export const USDT_WH = 'USDTWormhole'
export const USDC_WH = 'USDCWormhole'
export const USDT_LZ = 'USDTLZ'
export const WETH_LZ = 'WETH'
export const WETH_WH = 'WETHWormhole'
export const THL = 'THL'

export type SimpleCoin =
  | typeof COIN_A
  | typeof COIN_B
  | typeof COIN_C
  | typeof STH_APT
  | typeof AM_APT
  | typeof STAKED_APT
  | typeof USDT_WH
  | typeof USDC_WH
  | typeof USDT_LZ
  | typeof WETH_LZ
  | typeof WETH_WH
  | typeof THL

export type MoneyGunPayload = {
  address: string
  signAndSub: SignAndSubmitTransactionCallback
  coin: SimpleCoin
  isMSafe?: boolean
}

type SignAndSubmitTransactionArgs = Parameters<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
type SignAndSubmitTransactionReturnType = ReturnType<
  ReturnType<typeof useWallet>['signAndSubmitTransaction']
>
export type SignAndSubmitTransactionCallback = (
  ...args: SignAndSubmitTransactionArgs
) => SignAndSubmitTransactionReturnType

export const doMoneyGun = createAsyncThunk(
  'doMoneyGun',
  async (payload: MoneyGunPayload): Promise<any> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const mainURL = process.env.REACT_APP_APTOS_MAIN_URL
    if (!mainURL) {
      throw new Error(`Missing Aptos main URL: ${mainURL}`)
    }
    const useNetwork = mainURL.includes('testnet') ? Network.TESTNET : Network.MAINNET
    const aptosConfig = new AptosConfig({
      network: useNetwork
    })
    const aptos = new Aptos(aptosConfig)

    if (payload.isMSafe) {
      dismissNotifyThrow(
        'Faucet Not Available (MSafe)',
        'The faucet is not available in the MSafe experience. Please use faucet on the testnet app with another wallet, and send your test tokens to selected MSafe wallet.  Other app transactions are fully supported.'
      )
    }

    const rootAddr = process.env.REACT_APP_APTOS_FAUCET_ADDRESS
    if (!rootAddr) throw new Error('REACT_APP_APTOS_FAUCET_ADDRESS not set')

    const coinA = `${rootAddr}::coins::CoinA`
    const coinB = `${rootAddr}::coins::CoinB`
    const coinC = `${rootAddr}::coins::CoinC`
    const sthAPT = `${rootAddr}::coins::StakedThalaAPT`
    const amAPT = `${rootAddr}::coins::AmnisAPT`
    const stakedAPT = `${rootAddr}::coins::StakedAPT`
    const usdtWh = `${rootAddr}::coins::USDTWormhole`
    const usdcWh = `${rootAddr}::coins::USDCWormhole`
    const usdtLz = `${rootAddr}::coins::USDTLZ`
    const wethLz = `${rootAddr}::coins::WETH`
    const wethWh = `${rootAddr}::coins::WETHWormhole`
    const thl = `${rootAddr}::coins::THL`
    const coinStoreA =
      '0x1::coin::CoinStore<0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::CoinA>'
    const coinStoreB =
      '0x1::coin::CoinStore<0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::CoinB>'
    const coinStoreC =
      '0x1::coin::CoinStore<0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::CoinC>'
    const sthAPTStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::StakedThalaAPT'
    const amAPTStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::AmnisAPT'
    const stakedAPTStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::StakedAPT'
    const usdtWhStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::USDTWormhole'
    const usdcWhStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::USDCWormhole'
    const usdtLzStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::USDTLZ'
    const wethLzStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::WETH'
    const wethWhStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::WETHWormhole'
    const thlStore =
      '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::THL'

    const coinToCoinMap = {
      [COIN_A]: coinA,
      [COIN_B]: coinB,
      [COIN_C]: coinC,
      [STH_APT]: sthAPT,
      [AM_APT]: amAPT,
      [STAKED_APT]: stakedAPT,
      [USDT_WH]: usdtWh,
      [USDC_WH]: usdcWh,
      [USDT_LZ]: usdtLz,
      [WETH_LZ]: wethLz,
      [WETH_WH]: wethWh,
      [THL]: thl
    }

    const coinToStoreMap = {
      [COIN_A]: coinStoreA,
      [COIN_B]: coinStoreB,
      [COIN_C]: coinStoreC,
      [STH_APT]: sthAPTStore,
      [AM_APT]: amAPTStore,
      [STAKED_APT]: stakedAPTStore,
      [USDT_WH]: usdtWhStore,
      [USDC_WH]: usdcWhStore,
      [USDT_LZ]: usdtLzStore,
      [WETH_LZ]: wethLzStore,
      [WETH_WH]: wethWhStore,
      [THL]: thlStore
    }

    const useCoin = coinToCoinMap[payload.coin]
    const useCoinStore = coinToStoreMap[payload.coin]

    if (isEmptyOrNil(useCoin)) {
      throw new Error(`Invalid coin: ${payload.coin}`)
    }

    const args: AccountArgs = {
      accountAddress: payload.address
    }

    try {
      toastLoad('Checking APT balance...')
      const aptBalance = await aptos.getAccountAPTAmount(args)
      console.log('aptBalance', aptBalance)

      if (!aptBalance || aptBalance === 0) {
        throw new Error(
          'Use the faucet in your wallet to get Testnet APT tokens.  If you have, try refreshing the page.'
        )
      }
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Testnet APT not found', eMessage(e))
    }

    let resources: MoveResource[] = []
    let doRegister = false

    try {
      resources = await aptos.getAccountResources(args)
      doRegister = !resources.find((t) => t.type === useCoinStore)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Resources Not Found', eMessage(e))
    }

    if (doRegister) {
      const registerTx: InputTransactionData = {
        sender: payload.address,
        data: {
          function: `0x1::managed_coin::register`,
          typeArguments: [useCoin],
          functionArguments: []
        }
      }
      try {
        toastLoad('Registering Token...')
        await payload.signAndSub(registerTx)
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow('Token Not Registered', eMessage(e))
      }
    }

    const moneygunTx: InputTransactionData = {
      sender: payload.address,
      data: {
        function: `${rootAddr}::moneygun::shoot`,
        typeArguments: [useCoin],
        functionArguments: []
      }
    }
    try {
      toastLoad('Submitting faucet request...')
      const shot = await payload.signAndSub(moneygunTx)
      const args: WaitArgs = {
        transactionHash: shot.hash,
        options: {
          checkSuccess: true
        }
      }
      await aptos.waitForTransaction(args)
      toastSuccess('Transaction successful')
      postTransactionRefresh(payload.address)
    } catch (e: any) {
      console.error(e)
      dismissNotifyThrow('Transaction Not Completed', eMessage(e))
    }
  }
)
