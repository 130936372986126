import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'
import { createSelector } from 'reselect'
import { TabProps } from 'components/common/Tab'
import { BORROW, COLLATERAL, DashTab, selectDashTab, setDashboardTab } from './dash'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { doTx, TxReqPayload, tabToType, TxType } from 'state/thunks/doTx'
import { SignAndSubmitTransactionCallback } from 'state/thunks/doMoneyGun'
import { doMultiTx, MultiTxPayload } from 'state/thunks/doMultiTx'
import { doClosePosition, ClosePositionTxPayload } from 'state/thunks/doClosePosition'
import { SFlashLoanStrategy } from '@concordia/super-sdk/src/io'

export const DEPOSIT_TAB = 'Supply'
export const WITHDRAW_TAB = 'Withdraw'
export const BORROW_TAB = 'Borrow'
export const REPAY_TAB = 'Repay'

export const COLLATERAL_TABS: FormTab[] = [DEPOSIT_TAB, WITHDRAW_TAB]
export const BORROW_TABS: FormTab[] = [BORROW_TAB, REPAY_TAB]

export type FormTab =
  | typeof DEPOSIT_TAB
  | typeof WITHDRAW_TAB
  | typeof BORROW_TAB
  | typeof REPAY_TAB

export const ALL_HISTORY = 'All activity'
export const USER_HISTORY = 'Your activity'

export type HistoryTab = typeof ALL_HISTORY | typeof USER_HISTORY

export const HISTORY_TABS: HistoryTab[] = [ALL_HISTORY, USER_HISTORY]

export const SMALL = '0.1'
export const MEDIUM = '0.5'
export const LARGE = '1.0'

export type SlippageTab = typeof SMALL | typeof MEDIUM | typeof LARGE

export const SLIPPAGE_TABS: SlippageTab[] = [SMALL, MEDIUM, LARGE]
export interface FormState {
  formOpen: boolean
  activeBroker: MetaBroker
  formTab: FormTab
  inputVal: string
  txReview: boolean
  detailsOpen: boolean
  historyTab: HistoryTab
  multiplyFormOpen: boolean
  multiReviewOpen: boolean
  multiFormInputVal: string
  activeMultiBorrowBroker: MetaBroker
  activeMultiLendBroker: MetaBroker
  activeStrategy: SFlashLoanStrategy
  multiplier: number
  closeSlippage: string
  closePositionOpen: boolean
  slippageMenuOpen: boolean
  slippageTab: SlippageTab
  activeIncentiveData: IncentiveData
}

export const initialState: FormState = {
  formOpen: false,
  activeBroker: null,
  formTab: DEPOSIT_TAB,
  inputVal: '',
  txReview: false,
  detailsOpen: false,
  historyTab: ALL_HISTORY,
  multiplyFormOpen: false,
  multiReviewOpen: false,
  multiFormInputVal: '',
  activeMultiBorrowBroker: null,
  activeMultiLendBroker: null,
  activeStrategy: null,
  multiplier: 2,
  closePositionOpen: false,
  closeSlippage: '0.5',
  slippageMenuOpen: false,
  slippageTab: MEDIUM,
  activeIncentiveData: {
    supplyRewardRate: 0,
    borrowRewardRate: 0
  }
}

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormOpen: (s) => {
      s.formOpen = true
    },
    setFormClosed: (s) => {
      s.formOpen = false
      s.inputVal = ''
      s.txReview = false
    },
    setActiveBroker: (s, a: PayloadAction<MetaBroker>) => {
      s.activeBroker = a.payload
    },
    setActiveIncentiveData: (s, a: PayloadAction<IncentiveData>) => {
      s.activeIncentiveData = a.payload
    },
    setActiveMultiBorrowBroker: (s, a: PayloadAction<MetaBroker>) => {
      s.activeMultiBorrowBroker = a.payload
    },
    setActiveMultiLendBroker: (s, a: PayloadAction<MetaBroker>) => {
      s.activeMultiLendBroker = a.payload
    },
    setActiveStrategy: (s, a: PayloadAction<SFlashLoanStrategy>) => {
      s.activeStrategy = a.payload
    },
    setTab: (s, a: PayloadAction<FormTab>) => {
      s.formTab = a.payload
      s.inputVal = ''
    },
    setInputVal: (s, a: PayloadAction<string>) => {
      s.inputVal = a.payload
    },
    setFormInputVal: (s, a: PayloadAction<string>) => {
      s.multiFormInputVal = a.payload
    },
    setTxReviewOpen: (s) => {
      s.txReview = true
    },
    setTxReviewClosed: (s) => {
      s.txReview = false
    },
    setMultiReviewOpen: (s) => {
      s.multiReviewOpen = true
    },
    setMultiReviewClosed: (s) => {
      s.multiReviewOpen = false
    },
    setDetailsOpen: (s) => {
      s.detailsOpen = true
    },
    setDetailsClosed: (s) => {
      s.detailsOpen = false
    },
    setHistoryTab: (s, a: PayloadAction<HistoryTab>) => {
      s.historyTab = a.payload
    },
    setMultiplyFormOpen: (s) => {
      s.multiplyFormOpen = true
    },
    setMultiplyFormClosed: (s) => {
      s.multiplyFormOpen = false
      s.multiFormInputVal = ''
      s.multiplier = 2
    },
    setMultiplier: (s, a: PayloadAction<number>) => {
      s.multiplier = a.payload
    },
    setClosePositionOpen: (s) => {
      s.closePositionOpen = true
    },
    setClosePositionClosed: (s) => {
      s.closePositionOpen = false
    },
    setCloseSlippage: (s, a: PayloadAction<string>) => {
      s.closeSlippage = a.payload
    },
    setSlippageMenuOpen: (s) => {
      s.slippageMenuOpen = true
    },
    setSlippageMenuClosed: (s) => {
      s.slippageMenuOpen = false
    },
    setSlippageTab: (s, a: PayloadAction<SlippageTab>) => {
      s.slippageTab = a.payload
    },
    resetSlippageTab: (s) => {
      s.slippageTab = MEDIUM
    }
  }
})

export default slice.reducer

//selectors
export const selectFormOpen = (s: RootState) => s.ui.form.formOpen
export const selectActiveBroker = (s: RootState) => s.ui.form.activeBroker
export const selectFormTab = (s: RootState) => s.ui.form.formTab
export const selectInputVal = (s: RootState) => s.ui.form.inputVal
export const selectTxReviewOpen = (s: RootState) => s.ui.form.txReview
export const selectDetailsOpen = (s: RootState) => s.ui.form.detailsOpen
export const selectHistoryTab = (s: RootState) => s.ui.form.historyTab
export const selectMultiplyFormOpen = (s: RootState) => s.ui.form.multiplyFormOpen
export const selectMultiReviewOpen = (s: RootState) => s.ui.form.multiReviewOpen
export const selectMultiFormInputVal = (s: RootState) => s.ui.form.multiFormInputVal
export const selectActiveMultiBorrowBroker = (s: RootState) => s.ui.form.activeMultiBorrowBroker
export const selectActiveMultiLendBroker = (s: RootState) => s.ui.form.activeMultiLendBroker
export const selectMultiplier = (s: RootState) => s.ui.form.multiplier
export const selectClosePositionOpen = (s: RootState) => s.ui.form.closePositionOpen
export const selecttActiveStrategy = (s: RootState) => s.ui.form.activeStrategy
export const selectCloseSlippage = (s: RootState) => s.ui.form.closeSlippage
export const selectSlippageMenuOpen = (s: RootState) => s.ui.form.slippageMenuOpen
export const selectActiveIncentiveData = (s: RootState) => s.ui.form.activeIncentiveData

//dispatches
export const {
  setFormOpen,
  setFormClosed,
  setActiveBroker,
  setActiveIncentiveData,
  setTab,
  setInputVal,
  setTxReviewOpen,
  setTxReviewClosed,
  setDetailsOpen,
  setDetailsClosed,
  setHistoryTab,
  setMultiplyFormOpen,
  setMultiplyFormClosed,
  setMultiReviewOpen,
  setMultiReviewClosed,
  setFormInputVal,
  setActiveMultiBorrowBroker,
  setActiveMultiLendBroker,
  setMultiplier,
  setClosePositionOpen,
  setClosePositionClosed,
  setActiveStrategy,
  setCloseSlippage,
  setSlippageMenuOpen,
  setSlippageMenuClosed
} = slice.actions

export const openForm = () => {
  dispatch(setFormOpen())
}
export const closeForm = () => {
  dispatch(setFormClosed())
}
export const openDetails = () => {
  dispatch(setDetailsOpen())
}
export const closeDetails = () => {
  dispatch(setDetailsClosed())
}
export const setFormBroker = (b: MetaBroker) => {
  dispatch(setActiveBroker(b))
}
export const setFormIncentiveData = (iData: IncentiveData) => {
  dispatch(setActiveIncentiveData(iData))
}
export const setFormTab = (t: FormTab) => {
  dispatch(setTab(t))
}
export const openTxReview = () => {
  dispatch(setTxReviewOpen())
}
export const closeTxReview = () => {
  dispatch(setTxReviewClosed())
}
export const setTabHistory = (t: HistoryTab) => {
  dispatch(setHistoryTab(t))
}
export const openMultiplyFormWithBroker = (
  borrowBroker: MetaBroker,
  lendBroker: MetaBroker,
  strategy: SFlashLoanStrategy
) => {
  dispatch(setMultiplyFormOpen())
  setActiveMultiBrokers(borrowBroker, lendBroker)
  setStrategy(strategy)
}
export const closeMultiplyForm = () => {
  dispatch(setMultiplyFormClosed())
}
export const openMultiReview = () => {
  dispatch(setMultiReviewOpen())
}
export const closeMultiReview = () => {
  dispatch(setMultiReviewClosed())
}
export const setActiveMultiBrokers = (borrowBroker: MetaBroker, lendBroker: MetaBroker) => {
  dispatch(setActiveMultiBorrowBroker(borrowBroker))
  dispatch(setActiveMultiLendBroker(lendBroker))
}
export const setMultiplierVal = (val: number) => {
  dispatch(setMultiplier(val))
}

export const openClosePosition = () => {
  dispatch(setClosePositionOpen())
}

export const closeClosePosition = () => {
  dispatch(setClosePositionClosed())
  dispatch(setMultiplyFormClosed())
}

export const setStrategy = (s: SFlashLoanStrategy) => {
  dispatch(setActiveStrategy(s))
}

export const setCloseSlippageVal = (val: string) => {
  dispatch(setCloseSlippage(val))
}

export const openSlippageMenu = () => {
  dispatch(setSlippageMenuOpen())
}

export const closeSlippageMenu = () => {
  dispatch(setSlippageMenuClosed())
}

export interface IncentiveData {
  supplyRewardRate: number
  borrowRewardRate: number
}

export const openFormWithData = (
  b: MetaBroker,
  iData: IncentiveData,
  tab?: FormTab,
  dashTab?: DashTab
) => {
  if (dashTab) {
    setDashboardTab(dashTab)
  }
  setFormBroker(b)
  setFormIncentiveData(iData)
  if (tab) {
    setFormTab(tab)
  }
  openForm()
}

export const openDetailsWithBroker = (b: MetaBroker) => {
  setFormBroker(b)
  openDetails()
}

export const setInput = (val: string) => {
  dispatch(setInputVal(val))
}

export const setFormInput = (val: string) => {
  dispatch(setFormInputVal(val))
}

//prebuilds
export function switchFormLabels(tab: string) {
  switch (tab) {
    case COLLATERAL:
      return COLLATERAL_TABS
    case BORROW:
      return BORROW_TABS
  }
}
export const selectFormLabelsByDashTab = createSelector([selectDashTab], (tab) => {
  return switchFormLabels(tab)
})

export const selectBuiltFormTabs = createSelector(
  [selectFormTab, selectFormLabelsByDashTab],
  (selectedTab, labels) => {
    const tabs = buildFormTabs(labels, selectedTab)
    return tabs
  }
)

export const buildFormTabs = (labels: FormTab[], selected: FormTab) => {
  const dashTabs: TabProps[] = labels.map((label) => ({
    label,
    active: label === selected,
    onClick: () => dispatch(setTab(label))
  }))
  return dashTabs
}

export const buildHistoryTabs = (selected: HistoryTab) => {
  const historyTabs: TabProps[] = HISTORY_TABS.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setTabHistory(label)
  }))
  return historyTabs
}

export const buildSlippageTabs = (selected: string) => {
  const slippageTabs: TabProps[] = SLIPPAGE_TABS.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setCloseSlippageVal(label)
  }))
  return slippageTabs
}

export const selectBuiltHistoryTabs = createSelector([selectHistoryTab], (selectedTab) => {
  return buildHistoryTabs(selectedTab)
})

export const selectBuiltSlippageTabs = createSelector([selectCloseSlippage], (selectedTab) => {
  return buildSlippageTabs(selectedTab)
})

export function formAction(
  tab: FormTab,
  broker: MetaBroker,
  amount: number,
  address: string,
  positions: FormattedPositions,
  signAndSub: SignAndSubmitTransactionCallback,
  brokerNames: string[]
) {
  const txType: TxType = tabToType[tab]
  const payload: TxReqPayload = {
    txType,
    amount,
    broker,
    address,
    positions,
    signAndSub,
    brokerNames
  }

  dispatch(doTx(txType)(payload))
}

export function multiFormAction(payload: MultiTxPayload) {
  dispatch(doMultiTx(payload))
}

export function closePositionAction(payload: ClosePositionTxPayload) {
  dispatch(doClosePosition(payload))
}

export function calcNextFreeEquityVal(tab: FormTab, freeEquityVal: number, inputDollars: number) {
  if (tab === DEPOSIT_TAB || tab === REPAY_TAB) {
    return freeEquityVal + inputDollars
  } else {
    return freeEquityVal - inputDollars
  }
}

export function buildIncentiveData(
  b: MetaBroker,
  aptPrice: number,
  useIncentives: boolean
): IncentiveData {
  if (!b || !aptPrice || !useIncentives)
    return {
      supplyRewardRate: 0,
      borrowRewardRate: 0
    }
  const REWARD_PORTION = 0.25
  const APT_REWARD_AMOUNT = 1000
  const APT_REWARD_DOLLARS = APT_REWARD_AMOUNT * REWARD_PORTION * aptPrice
  const brokerSupply = b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying
  const brokerSupplyValue = brokerSupply * b.underlyingAsset.price

  const supplyRewardRate = (APT_REWARD_DOLLARS / brokerSupplyValue) * 52
  const brokerBorrowValue =
    brokerSupplyValue - b?.scaledAvailableLiquidityUnderlying * b?.underlyingAsset.price

  const borrowRewardRate = (APT_REWARD_DOLLARS / brokerBorrowValue) * 52
  const incentiveData: IncentiveData = {
    supplyRewardRate,
    borrowRewardRate
  }
  return incentiveData
}
