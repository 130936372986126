import { formatCurrency, prettyTokenBal } from 'toolbox/format'
import MetaDisplay, { MetaDisplayProps } from './MetaDisplay'
import { isEmptyOrNil, noteNameToBroker } from 'toolbox/account'
import { BORROW, COLLATERAL, DashTab } from 'state/slices/ui/dash'
import { selectFormattedPositions } from 'state/slices/user/portfolio'
import { useAppSelector } from 'state/hooks'
import { MetaBroker } from 'state/mock'
import { types } from '@concordia/super-sdk'
import { getPriceFromName } from 'state/slices/app/brokers'
import { colorClassMapping } from './BalanceChart'

export interface BalancesListProps {
  isLoadedUser: boolean
  prices: types.NameBalanceMap
  brokers: MetaBroker[]
  tab: DashTab
}

function BalancesList({ isLoadedUser, prices, brokers, tab }: BalancesListProps) {
  const positions = useAppSelector(selectFormattedPositions)

  const isDeposit = tab === COLLATERAL
  const bals = () => {
    switch (tab) {
      case COLLATERAL:
        return positions.collaterals
      case BORROW:
        return positions.liabilities
    }
  }

  const buildDisplay = (
    bals: types.NameBalanceMap,
    noteName: string,
    totalValue: number,
    isDeposit: boolean
  ) => {
    const findBroker = noteNameToBroker(brokers, noteName)
    if (isEmptyOrNil(findBroker)) {
      return null
    } else {
      const tokenMeta = findBroker.tokenMeta
      const chainMeta = findBroker.chainMeta
      const userPosition = bals[noteName]

      if (userPosition > 0.00009999) {
        const price = getPriceFromName(noteName, prices)
        const exchangeRate = isDeposit
          ? findBroker.depositNoteExchangeRate
          : findBroker.loanNoteExchangeRate
        const exchangeRateBalance = userPosition * exchangeRate
        const exchangeRateValue = exchangeRateBalance * price
        const percentageOfTotal = totalValue > 0 ? (exchangeRateValue / totalValue) * 100 : 0

        return {
          firstIcon: chainMeta.logoURL,
          secondIcon: tokenMeta.logo,
          value: `${tokenMeta.ticker}`,
          sub: `${prettyTokenBal(exchangeRateBalance)}`,
          secondarySub: `${formatCurrency(exchangeRateValue)}`,
          percentage: `${percentageOfTotal.toFixed(2)}%`,
          isLoadedAny: isLoadedUser,
          color: colorClassMapping[findBroker.underlyingAsset.name.toLowerCase()],
          divider: true,
          valueForSort: exchangeRateValue
        }
      } else {
        return null
      }
    }
  }

  let idKeys = []
  let totalDollarValue = 0
  if (!isEmptyOrNil(bals())) {
    idKeys = Object.keys(bals())
    totalDollarValue = idKeys.reduce((acc, name) => {
      const userPosition = bals()[name]
      if (userPosition > 0.00009999) {
        const price = getPriceFromName(name, prices)
        const dollarVal = userPosition * price
        return acc + dollarVal
      }
      return acc
    }, 0)
  }
  const list: MetaDisplayProps[] = idKeys
    ?.map((name) => {
      return buildDisplay(bals(), name, totalDollarValue, isDeposit)
    })
    .sort((a, b) => b?.valueForSort - a?.valueForSort)

  const display = list?.map((obj, i) => !!obj && <MetaDisplay key={i} {...obj} />)

  return <div className="balances-list">{display}</div>
}
export default BalancesList
