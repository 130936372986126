"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiplySDK = void 0;
const aptos_1 = require("aptos");
const thala_1 = require("./thala");
class MultiplySDK {
    /**
     * @param multiplyAddress 0x-prefixed address
     */
    constructor(multiplyAddress, superpAddress) {
        this.multiplyAddress = multiplyAddress;
        this.superpAddress = superpAddress;
        this.thalaSDK = new thala_1.ThalaSDK();
    }
    openMultiplyVaultIx(lendAndBorrowPacket, principalAmount, flashLoanAmount, collateralCoinType, liabilityCoinType) {
        return {
            type: "entry_function_payload",
            function: `${this.multiplyAddress}::multiply_public::open_vault`,
            type_arguments: [collateralCoinType, liabilityCoinType],
            arguments: [lendAndBorrowPacket, principalAmount, flashLoanAmount],
        };
    }
    async getVaultAddress(aptosClient, vaultOwner, collateralCoinType, liabilityCoinType) {
        const [vaultAddress] = (await aptosClient.view({
            function: `${this.multiplyAddress}::multiply_public::get_vault_address`,
            arguments: [vaultOwner],
            type_arguments: [collateralCoinType, liabilityCoinType],
        }));
        return vaultAddress;
    }
    async getMultiplyLeverage(aptosClient, vaultAddress, collateralCoinType, liabilityCoinType) {
        const leverage = (await aptosClient.view({
            function: `${this.multiplyAddress}::multiply_public::get_vault_leverage`,
            type_arguments: [collateralCoinType, liabilityCoinType],
            arguments: [vaultAddress],
        }));
        if (leverage[0].principal_amount == 0 || leverage[0].flash_loan_amount == 0) {
            return 0;
        }
        const numerator_amount = Number(leverage[0].flash_loan_amount) + Number(leverage[0].principal_amount);
        return numerator_amount / leverage[0].principal_amount;
    }
    typeInfoToString(typeInfo) {
        const moduleNameHexString = new aptos_1.HexString(typeInfo.module_name);
        const moduleNameUint8Array = moduleNameHexString.toUint8Array();
        const moduleNameString = new TextDecoder().decode(moduleNameUint8Array);
        const structNameHexString = new aptos_1.HexString(typeInfo.struct_name);
        const structNameUint8Array = structNameHexString.toUint8Array();
        const structNameString = new TextDecoder().decode(structNameUint8Array);
        return `${typeInfo.account_address}::${moduleNameString}::${structNameString}`;
    }
    async getMainnetSthaptCollateralBalance(aptosClient, portfolioView) {
        const sthaptDepositNoteBalance = parseInt(portfolioView.collaterals[0].instrument_balance);
        let totalSthaptDeposited;
        {
            const collateralCoinType = '0xfaf4e633ae9eb31366c9ca24214231760926576c7b625313b3688b5e900731f6::staking::StakedThalaAPT';
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::broker::deposited`,
                arguments: [],
                type_arguments: [collateralCoinType],
            });
            totalSthaptDeposited = parseInt(viewResult[0]);
        }
        let depositNoteSupply;
        {
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::broker::deposit_note_supply`,
                arguments: [],
                type_arguments: ['0xfaf4e633ae9eb31366c9ca24214231760926576c7b625313b3688b5e900731f6::staking::StakedThalaAPT'],
            });
            depositNoteSupply = parseInt(viewResult[0]);
        }
        const sthaptCollateralCoinBalance = sthaptDepositNoteBalance * totalSthaptDeposited / depositNoteSupply;
        return sthaptCollateralCoinBalance;
    }
    async getMainnetAptLiabilityBalance(aptosClient, portfolioView) {
        const aptLiabilityNoteBalance = parseInt(portfolioView.liabilities[0].instrument_balance);
        let totalAptBorrowed;
        {
            const liabilityCoinType = '0x1::aptos_coin::AptosCoin';
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::broker::borrowed`,
                arguments: [],
                type_arguments: [liabilityCoinType],
            });
            totalAptBorrowed = parseInt(viewResult[0]);
        }
        let loanNoteSupply;
        {
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::broker::loan_note_supply`,
                arguments: [],
                type_arguments: ['0x1::aptos_coin::AptosCoin'],
            });
            loanNoteSupply = parseInt(viewResult[0]);
        }
        const aptLiabilityCoinBalance = aptLiabilityNoteBalance * totalAptBorrowed / loanNoteSupply;
        return aptLiabilityCoinBalance;
    }
    async estimateMainnetSthaptRedemptionAmount({ aptosClient, vaultAddress, }) {
        let portfolioViewRaw;
        {
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::portfolio::portfolio_view`,
                arguments: [vaultAddress],
                type_arguments: [],
            });
            portfolioViewRaw = viewResult[0];
        }
        const portfolioView = portfolioViewRaw;
        const sthaptCollateralBalance = await this.getMainnetSthaptCollateralBalance(aptosClient, portfolioView);
        if (sthaptCollateralBalance == 0) {
            return 0;
        }
        const aptLiabilityBalance = await this.getMainnetAptLiabilityBalance(aptosClient, portfolioView);
        // 0.3% flash loan fee
        const flashLoanFeeRate = 0.003;
        const aptFlashLoanFee = aptLiabilityBalance * flashLoanFeeRate;
        const aptFlashLoanRepayment = aptLiabilityBalance + aptFlashLoanFee;
        const estimatedTotalAptBeforeRepay = await this.thalaSDK.estimateSthaptToApt({
            aptosClient,
            sthaptAmountIn: sthaptCollateralBalance,
        });
        const estimatedAptRedemptionAmount = estimatedTotalAptBeforeRepay - aptFlashLoanRepayment;
        return estimatedAptRedemptionAmount;
    }
    /**
     * Estimate the amount of liabilityCoinType coins that will be
     * redeemed by the user when the vault is closed
     */
    async estimateClosingRedemptionAmount({ aptosClient, vaultAddress, collateralCoinType, liabilityCoinType, }) {
        {
            const viewResult = await aptosClient.view({
                function: `${this.superpAddress}::portfolio::portfolio_view`,
                arguments: [vaultAddress],
                type_arguments: [],
            });
            const portfolioViewRaw = viewResult[0];
            const portfolioView = portfolioViewRaw;
            if (portfolioView.collaterals.length < 1) {
                // vault not created yet
                return 0;
            }
        }
        if (collateralCoinType == '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::StakedThalaAPT' && liabilityCoinType == '0xdf7256dba0d07aafdd98dafb50cade82db360bc5a5003c170ae88ffd1769fd85::coins::CoinB') {
            const [redemptionAmount] = (await aptosClient.view({
                function: `${this.multiplyAddress}::multiply_public::estimate_closing_redemption_amount`,
                type_arguments: [collateralCoinType, liabilityCoinType],
                arguments: [vaultAddress],
            }));
            return parseInt(redemptionAmount);
        }
        else if (collateralCoinType == `0xfaf4e633ae9eb31366c9ca24214231760926576c7b625313b3688b5e900731f6::staking::StakedThalaAPT` && liabilityCoinType == '0x1::aptos_coin::AptosCoin') {
            return this.estimateMainnetSthaptRedemptionAmount({ aptosClient, vaultAddress });
        }
        else {
            throw new Error(`Unsupported coin types: ${collateralCoinType} ${liabilityCoinType}`);
        }
    }
    closeMultiplyVaultIx(repayAndRedeemPacket, collateralCoinType, liabilityCoinType, minLiabilityCoinAmountOut) {
        const unused = 0;
        return {
            type: "entry_function_payload",
            function: `${this.multiplyAddress}::multiply_public::close_vault`,
            arguments: [repayAndRedeemPacket, minLiabilityCoinAmountOut, unused],
            type_arguments: [collateralCoinType, liabilityCoinType],
        };
    }
}
exports.MultiplySDK = MultiplySDK;
