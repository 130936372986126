"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultService = void 0;
class DefaultService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns Broker Ok
     * @throws ApiError
     */
    getBrokers() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers',
        });
    }
    /**
     * Request a broker by network address
     * @param networkAddress
     * @returns Broker A broker
     * @throws ApiError
     */
    getBrokerByAddress(networkAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/address/{networkAddress}',
            path: {
                'networkAddress': networkAddress,
            },
        });
    }
    /**
     * @param id
     * @returns Broker Ok
     * @throws ApiError
     */
    getBrokerById(id) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/address/id/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Request to lend to a broker
     * @param requestBody The request object
     * @returns PacketResponse A Hocket packet
     * @throws ApiError
     */
    lendV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brokers/lend/v2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request to redeem from a broker (reverse of lend)
     * @param requestBody The request object
     * @returns PacketResponse A Hocket packet
     * @throws ApiError
     */
    redeemV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brokers/redeem/v2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request to borrow from a broker
     * @param requestBody The request object
     * @returns PacketResponse A Hocket packet
     * @throws ApiError
     */
    borrowV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brokers/borrow/v2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request to repay from a broker (reverse of borrow)
     * @param requestBody The request object
     * @returns PacketResponse A Hocket packet
     * @throws ApiError
     */
    repayV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brokers/repay/v2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CoreTypes_BrokerGuardrail Ok
     * @throws ApiError
     */
    getGuardrails() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/guardrails',
        });
    }
    /**
     * @param networkAddresses
     * @param from
     * @param to
     * @param offset
     * @param size
     * @returns any Ok
     * @throws ApiError
     */
    getTransactions(networkAddresses, from, to, offset, size) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/transactions',
            query: {
                'networkAddresses': networkAddresses,
                'from': from,
                'to': to,
                'offset': offset,
                'size': size,
            },
        });
    }
    /**
     * @param userAddress
     * @param networkAddresses
     * @param from
     * @param to
     * @param offset
     * @param size
     * @returns any Ok
     * @throws ApiError
     */
    getTransactionsForUser(userAddress, networkAddresses, from, to, offset, size) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/transactions/user/{userAddress}',
            path: {
                'userAddress': userAddress,
            },
            query: {
                'networkAddresses': networkAddresses,
                'from': from,
                'to': to,
                'offset': offset,
                'size': size,
            },
        });
    }
    /**
     * @param networkAddress
     * @param from
     * @param to
     * @param offset
     * @param size
     * @returns any Ok
     * @throws ApiError
     */
    getHistory(networkAddress, from, to, offset, size) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/history/{networkAddress}',
            path: {
                'networkAddress': networkAddress,
            },
            query: {
                'from': from,
                'to': to,
                'offset': offset,
                'size': size,
            },
        });
    }
    /**
     * @returns CoreTypes_EventHistoryItem Ok
     * @throws ApiError
     */
    getLendTransactions() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/transactions/lend',
        });
    }
    /**
     * @param networkAddresses
     * @param from
     * @param to
     * @param offset
     * @param size
     * @returns any Ok
     * @throws ApiError
     */
    updateTransactions(networkAddresses, from, to, offset, size) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brokers/transactions/update',
            query: {
                'networkAddresses': networkAddresses,
                'from': from,
                'to': to,
                'offset': offset,
                'size': size,
            },
        });
    }
    /**
     * @returns boolean Ok
     * @throws ApiError
     */
    checkRegionOk() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ip/checkRegion',
        });
    }
    /**
     * @returns CoreTypes_FlashLoanStrategy Ok
     * @throws ApiError
     */
    getStrategies() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/multiply/strategies',
        });
    }
    /**
     * @param userAddress
     * @param lendToken
     * @param borrowToken
     * @returns CoreTypes_UserStrategy Ok
     * @throws ApiError
     */
    getUserVault(userAddress, lendToken, borrowToken) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/multiply/vault/{userAddress}/{lendToken}/{borrowToken}',
            path: {
                'userAddress': userAddress,
                'lendToken': lendToken,
                'borrowToken': borrowToken,
            },
        });
    }
    /**
     * @returns string Ok
     * @throws ApiError
     */
    listPortfolios() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/list',
        });
    }
    /**
     * @param requestBody
     * @returns PacketResponse Ok
     * @throws ApiError
     */
    repayAndRedeemV1(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portfolios/repayandredeem/v1',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PacketResponse Ok
     * @throws ApiError
     */
    lendAndBorrowV1(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portfolios/lendandborrow/v1',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PacketResponse Ok
     * @throws ApiError
     */
    liquidateV1(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portfolios/liquidate/v1',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PacketResponse Ok
     * @throws ApiError
     */
    liquidateV2(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/portfolios/liquidate/v2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Request a portfolio by network address
     * @param address
     * @returns types_PortfolioWithRisk A portfolio
     * @throws ApiError
     */
    getPortfolio(address) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/{address}',
            path: {
                'address': address,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @param from
     * @param to
     * @param offset
     * @param size
     * @returns any Ok
     * @throws ApiError
     */
    getPortfolioTransactions(portfolioAddress, from, to, offset, size) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/{portfolio_address}',
            path: {
                'portfolio_address': portfolioAddress,
            },
            query: {
                'from': from,
                'to': to,
                'offset': offset,
                'size': size,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @param action
     * @returns any Ok
     * @throws ApiError
     */
    getPortfolioHasTypedTransaction(portfolioAddress, action) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/{portfolio_address}/{action}',
            path: {
                'portfolio_address': portfolioAddress,
                'action': action,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @param action
     * @returns any Ok
     * @throws ApiError
     */
    getPortfolioHasTypedTransactionCount(portfolioAddress, action) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/{portfolio_address}/{action}/count',
            path: {
                'portfolio_address': portfolioAddress,
                'action': action,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @param action
     * @param coin
     * @returns any Ok
     * @throws ApiError
     */
    getPortfolioHasCoinAction(portfolioAddress, action, coin) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/{portfolio_address}/{action}/coin/{coin}',
            path: {
                'portfolio_address': portfolioAddress,
                'action': action,
                'coin': coin,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @returns any Ok
     * @throws ApiError
     */
    getPortfolioTransactionsHistory(portfolioAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/history/{portfolio_address}',
            path: {
                'portfolio_address': portfolioAddress,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @returns CoreTypes_PortfolioHistoryByDateTotals Ok
     * @throws ApiError
     */
    getPortfolioTransactionsHistoryTotals(portfolioAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/history/totals/{portfolio_address}',
            path: {
                'portfolio_address': portfolioAddress,
            },
        });
    }
    /**
     * @returns CoreTypes_PointsHistoryTotals Ok
     * @throws ApiError
     */
    getLatestPointsHistoryTotals() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/transaction/history/points/latest',
        });
    }
    /**
     * @param address
     * @returns any Ok
     * @throws ApiError
     */
    getConnectedPortfolios(address) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/portfolios/{address}/connected',
            path: {
                'address': address,
            },
        });
    }
    /**
     * Get latest prices, in USD decimal
     * @param names unique names of instruments
     * @returns PricesResponse Ok
     * @throws ApiError
     */
    getPrices(names) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/prices',
            query: {
                'names': names,
            },
        });
    }
    /**
     * Get historical price data for the last year
     * @param names unique names of instruments
     * @returns CoreTypes_HistoricalPrices Ok
     * @throws ApiError
     */
    getPriceHistoryForYear(names) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/prices/historical/year',
            query: {
                'names': names,
            },
        });
    }
    /**
     * @returns CoreTypes_FullInstrumentsResponse Ok
     * @throws ApiError
     */
    getFullInstrumentPriceHistory() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/prices/full',
        });
    }
    /**
     * @param portfolioAddress
     * @returns CoreTypes_Referal Ok
     * @throws ApiError
     */
    getReferals(portfolioAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referals/{portfolio_address}',
            path: {
                'portfolio_address': portfolioAddress,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @returns any Ok
     * @throws ApiError
     */
    getReferalCode(portfolioAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referals/{portfolio_address}/code',
            path: {
                'portfolio_address': portfolioAddress,
            },
        });
    }
    /**
     * @param portfolioAddress
     * @param requestBody
     * @returns CoreTypes_Referal Ok
     * @throws ApiError
     */
    createReferal(portfolioAddress, requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/referals/{portfolio_address}/refer',
            path: {
                'portfolio_address': portfolioAddress,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param portfolioAddress
     * @returns CoreTypes_ReferralsByUser Ok
     * @throws ApiError
     */
    getReferralsByUser(portfolioAddress) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/referals/{portfolio_address}/referralsByUser',
            path: {
                'portfolio_address': portfolioAddress,
            },
        });
    }
    /**
     * @param names
     * @returns CoreTypes_RiskParamsHistory Ok
     * @throws ApiError
     */
    getRiskParamsHistory(names) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/risk/params/history',
            query: {
                'names': names,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Evaluation Ok
     * @throws ApiError
     */
    getRiskSimulated(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/risk/simulated',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param address
     * @returns CoreTypes_PortfolioWithFullRisk Ok
     * @throws ApiError
     */
    getRiskFull(address) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/risk/full/{address}',
            path: {
                'address': address,
            },
        });
    }
    /**
     * @param address
     * @returns CoreTypes_RiskInput Ok
     * @throws ApiError
     */
    getRiskInputs(address) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/risk/inputs/{address}',
            path: {
                'address': address,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CoreTypes_RiskEval Ok
     * @throws ApiError
     */
    setRiskCustom(requestBody) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/risk/custom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns FeatureToggles Ok
     * @throws ApiError
     */
    getToggles() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/toggles',
        });
    }
    /**
     * @param toggleName
     * @returns boolean Ok
     * @throws ApiError
     */
    getToggle(toggleName) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/toggles/{toggleName}',
            path: {
                'toggleName': toggleName,
            },
        });
    }
    /**
     * @param address
     * @returns WalletBalances Ok
     * @throws ApiError
     */
    getBalances(address) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/wallets/{address}',
            query: {
                'address': address,
            },
        });
    }
}
exports.DefaultService = DefaultService;
