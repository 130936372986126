import { types } from '@concordia/super-sdk'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { BORROW, DashTab, selectIsBlocked, showBlock } from 'state/slices/ui/dash'
import { buildIncentiveData, FormTab, openFormWithData } from 'state/slices/ui/form'
import { calcUtil } from 'toolbox/calc'
import { formatPercentage, prettyTokenBal } from 'toolbox/format'
import { COLLATERAL } from './CollateralRow'
import { useAppSelector } from 'state/hooks'
import ActionCircle from 'components/common/ActionCircle'
import { calcBorrowData, calcSupplyData } from 'state/slices/user/portfolio'
import ParamList from 'components/txForm/ParamList'
import { getPriceFromName, selectPricesFromBrokers } from 'state/slices/app/brokers'
import { selectIncentivesToggleOn } from 'state/slices/app/toggles'

export interface MobileBrokerCardProps {
  broker: MetaBroker
  tab: DashTab
  isLoadedUser: boolean
  positions: FormattedPositions
  balances: types.SWalletBalance[]
  showBalRow: boolean
  isLoadedApp: boolean
  formTabs: FormTab[]
  userCollat: number
  isLoadedBrokers: boolean
}

export function MobileBrokerCard({
  broker,
  tab,
  isLoadedUser,
  positions,
  balances,
  showBalRow,
  isLoadedApp,
  formTabs,
  userCollat,
  isLoadedBrokers
}: MobileBrokerCardProps) {
  const brokerBorrowed = broker?.scaledTotalBorrowedUnderlying
  const totalAvailable = broker?.scaledAvailableLiquidityUnderlying
  const brokerTotal = brokerBorrowed + totalAvailable
  const utilization = calcUtil(broker)
  const maxSupplyBroker = Number(broker?.maxDepositScaled)
  const t = broker.tokenMeta
  const prices = useAppSelector(selectPricesFromBrokers)
  const aptPrice = getPriceFromName('aptos', prices)

  const maxParam =
    tab === BORROW
      ? null
      : {
          label: 'Pool max limit',
          value: prettyTokenBal(maxSupplyBroker),
          tooltip: 'Maximum liquidity that can be supplied to the pool'
        }

  //broker params and projections
  const brokerStats = [
    {
      label: 'Total Available In Broker',
      value: prettyTokenBal(totalAvailable),
      tooltip: `Liquidity available for borrowing or withdrawal in tokens`
    },
    {
      label: 'Total Loaned By Broker',
      value: prettyTokenBal(brokerBorrowed),
      tooltip: `Liquidity currently loaned to borrowers in tokens`
    },
    {
      label: 'Total Supplied In Broker',
      value: prettyTokenBal(brokerTotal),
      tooltip: 'Liquidity supplied in tokens'
    },
    {
      label: 'Utilization',
      value: formatPercentage(utilization),
      tooltip: 'Ratio of debt / collateral.  High utilization increases interest'
    }
  ]

  const isSupply = tab === COLLATERAL
  const isBlocked = useAppSelector(selectIsBlocked)
  const hasSupplied = userCollat > 0
  const hasPower = 0 < 1
  const canBorrow = hasSupplied && hasPower
  const incentivesToggleOn = useAppSelector(selectIncentivesToggleOn)
  const useIncentives =
    (incentivesToggleOn && broker?.underlyingAsset.name === 'usdtlz') ||
    (incentivesToggleOn && broker?.underlyingAsset.name === 'usdc')
  const incentiveData = buildIncentiveData(broker, aptPrice, useIncentives)

  const openAdd = () =>
    isBlocked ? showBlock() : openFormWithData(broker, incentiveData, formTabs[0])
  const open = () =>
    isBlocked ? showBlock() : openFormWithData(broker, incentiveData, formTabs[1])
  const disAddDeposit = !isLoadedUser
  const disAddBorrow = !isLoadedUser || !canBorrow
  const borrowData = calcBorrowData(positions, broker)
  const supplyData = calcSupplyData(positions, broker, utilization)
  const disRemoveDeposit = !isLoadedUser || !supplyData.hasPosition
  const disRemoveBorrow = !isLoadedUser || !borrowData.hasPosition

  const disabledAddSwitch = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return disAddDeposit
      case BORROW:
        return disAddBorrow
    }
  }

  const disabledMinusSwitch = (tab: DashTab) => {
    switch (tab) {
      case COLLATERAL:
        return disRemoveDeposit
      case BORROW:
        return disRemoveBorrow
    }
  }
  const buttons = (
    <div className="broker-buttons">
      <ActionCircle cb={openAdd} disabled={disabledAddSwitch(tab)} isAdd isSupply={isSupply} />
      <ActionCircle cb={open} disabled={disabledMinusSwitch(tab)} isSupply={isSupply} />
    </div>
  )
  return (
    <div className="mobile-broker-card">
      <div className="top-card">
        <div className="basic-meta small">
          <img src={t.logo} alt={t.name} />
          <div className="names">
            <p className="name">{t.ticker}</p>
            <p className="full-name">{t.displayName}</p>
          </div>
        </div>
        {buttons}
      </div>
      <hr className="hr" />
      <ParamList params={brokerStats} more={brokerStats} />
    </div>
  )
}
